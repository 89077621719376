export type ExtendedErrorOptions<Code extends string> = {
  code: Code;
  data?: unknown;
  message: string;
  name?: string;
};

export type ExtendedErrorFactoryOptions<Code extends string> = Omit<
  ExtendedErrorOptions<Code>,
  'message'
> & { message?: string };

export class ExtendedError<Code extends string> extends globalThis.Error {
  code: Code;
  data?: ExtendedErrorOptions<Code>['data'];
  override message: ExtendedErrorOptions<Code>['message'];
  override name: NonNullable<ExtendedErrorOptions<Code>['name']>;

  constructor({
    code,
    data,
    message = '',
    name = 'ExtendedError',
  }: ExtendedErrorOptions<Code>) {
    super(message);
    Object.setPrototypeOf(this, ExtendedError.prototype);
    Object.defineProperty(this, 'message', {
      enumerable: true,
    });

    this.code = code;
    this.data = data;
    this.message = message;
    this.name = name;
  }
}
